<template>
<td>
    <div class="container pl-0" v-if="exists">
        <CLink :to="`/bonuses/view/${reward.sku}`" class="text-info">
        <CImg v-if="reward.status!='Missing'"
            :src="reward.imageUrl" 
            :class="{'opacity-1': reward.status!='Available' }" 
            :title="reward.title"
            width="45" height="45" class="mr-2 ml-0 pl-0"  />  
        </CLink>
        <CBadge v-if="reward.status=='Missing'" color="danger" style="width:45; height: 15px" class="mr-2 ml-0 mt-1" >Missing!</CBadge>
        <div class="info">
            <div class="d-flex align-items-start">
                <CLink :to="`/bonuses/view/${reward.sku}`" class="text-info"><span :class="{'text-info':true}">{{ reward.sku }}</span></CLink>
                <CBadge v-if="isUnavailable" color="danger" class="mt-1 ml-1">
                    Unavailable
                </CBadge>
                <CIcon v-if="isAvailable" name="cil-check-alt" class="text-success mt-1 ml-1" size="sm" />
            </div>
            <CLink :to="`/bonuses/view/${reward.sku}`" :class="{'text-primary': isAvailable, 'text-secondary': !isAvailable }"><span :class="{'title':true}" :title="reward.title">{{ reward.title }}</span></CLink>
            <div v-if="reward.status=='Missing'" class="text-danger small mt-1">Missing</div>
        </div>
    </div>
    <!-- <span v-if="exists">{{ reward.status }}</span> -->    
    <CButton v-if="isNext" color="primary" size="sm" :to="`/products/view/${sku}?rewards=new`">
        <span class="strong">+</span>
    </CButton>
</td>
</template>
<script>
export default {
    props: {
        sku: String,
        rewards: Array,
        index: Number
    },
    computed: {
        exists() {
            return this.rewards && (this.rewards.length >= (this.index + 1));
        },
        isNext() {
            return this.rewards && (this.rewards.length == this.index);
        },        
        reward() {
            return this.exists ? this.rewards[this.index] : null;
        },
        isAvailable() {
            return this.reward ? this.reward.status==='Available' : null;
        },
        isUnavailable() {
            return this.reward ? this.reward.status==='OutOfStock' : null;
        }
    },
    data() {
        return {
        }
    }
}
</script>

<style scoped>
.title {
    white-space: nowrap; overflow:hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 160px;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;  
  align-items: flex-start; 
  max-width: 160px;  
  min-width: 40px; 
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>