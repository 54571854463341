<template>
    <ProductSettingsList mode='rewards' title='Product List - Rewards'>
         <template v-slot:table>
            <CDataTable
                :items="products"
                :items-per-page="10"
                pagination
                :loading="isLoading"      
                items-per-page-select       
                cleaner
                sorter
                table-filter
                hover
                @row-clicked="rowClicked"
                 :fields="[
                 { key:'imageUrl', _style: 'width: 60px', label: ''},
                 { key:'sku' },
                 { key:'reward1', label: 'Reward 1', _style: 'width: 20%' },
                 { key:'reward2', label: 'Reward 2', _style: 'width: 20%' },
                 { key:'reward3', label: 'Reward 3', _style: 'width: 20%' },
                 { key:'more', label: 'More..', _style: 'width: 90px' },
                 { key:'new', label: '', _style: 'width: 90px' },
                 ]"
                >               
                <template #imageUrl="{item}">
                    <ProductsListImageColumn :rowData='item' :disabled="!item.isActive" />
                </template>
                <template #sku="{item}">    
                    <td style="text-overflow: ellipsis; overflow:hidden" >                        
                        <span class="strong" :title="item.description">{{ item.sku}}</span><br />
                        <span style="white-space: nowrap;">{{ item.title }}</span>
                    </td>
                </template>
                <template #reward1="{item}">
                    <ProductRewardColumn :rewards="item.rewards" :index="0" :sku="item.sku" />
                </template>
                <template #reward2="{item}">
                    <ProductRewardColumn :rewards="item.rewards" :index="1" :sku="item.sku" />
                </template>
                <template #reward3="{item}">
                    <ProductRewardColumn :rewards="item.rewards" :index="2" :sku="item.sku" />
                </template>
                 <template #more="{item}">
                    <td>
                        <CButton v-if="item.rewards && item.rewards.length > 3" size="sm" color="success">{{ item.rewards.length - 3 }}&nbsp;more...</CButton>
                        <!-- <span class="strong text-info" v-if="item.rewards && item.rewards.length > 3">{{ item.rewards.length - 3 }}&nbsp;more...</span> -->
                    </td>
                </template>
                <template #new="{item}">
                    <td>
                        <CButton v-if="item.rewards && item.rewards.length >= 3" color="primary" size="sm" :to="`/products/view/${item.sku}?rewards=new`">
                            <span class="strong">+</span>
                        </CButton>
                    </td>
                </template>
            </CDataTable>
        </template>
    </ProductSettingsList>
</template>

<script>
import ProductSettingsList from '@/components/ProductSettingsList';
import ProductRewardColumn from '@/components/ProductRewardColumn';
import ProductsListImageColumn from '@/components/ProductsListImageColumn';
import { mapGetters, mapActions } from 'vuex';
import { actionNames } from '@/store/modules/products/actions';

export default {
    components: {
        ProductSettingsList,
        ProductsListImageColumn,
        ProductRewardColumn
    },
    computed: {
       ...mapGetters({
            products: 'products/products', 
            isLoading: 'products/loading',
            merchantId: 'merchantId' }
        )
    },
    methods: {
        ...mapActions({
            loadProducts: actionNames.LOAD_PRODUCTS_REWARDS
        }),
        rowClicked(item) {
            this.$router.push(`/products/view/${item.sku}?rewards=true`);
        }
    },
    created() {
        if (this.merchantId != null) {
            this.loadProducts();
        }
    }
}
</script>