var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ProductSettingsList',{attrs:{"mode":"rewards","title":"Product List - Rewards"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.products,"items-per-page":10,"pagination":"","loading":_vm.isLoading,"items-per-page-select":"","cleaner":"","sorter":"","table-filter":"","hover":"","fields":[
             { key:'imageUrl', _style: 'width: 60px', label: ''},
             { key:'sku' },
             { key:'reward1', label: 'Reward 1', _style: 'width: 20%' },
             { key:'reward2', label: 'Reward 2', _style: 'width: 20%' },
             { key:'reward3', label: 'Reward 3', _style: 'width: 20%' },
             { key:'more', label: 'More..', _style: 'width: 90px' },
             { key:'new', label: '', _style: 'width: 90px' } ]},on:{"row-clicked":_vm.rowClicked},scopedSlots:_vm._u([{key:"imageUrl",fn:function(ref){
             var item = ref.item;
return [_c('ProductsListImageColumn',{attrs:{"rowData":item,"disabled":!item.isActive}})]}},{key:"sku",fn:function(ref){
             var item = ref.item;
return [_c('td',{staticStyle:{"text-overflow":"ellipsis","overflow":"hidden"}},[_c('span',{staticClass:"strong",attrs:{"title":item.description}},[_vm._v(_vm._s(item.sku))]),_c('br'),_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(item.title))])])]}},{key:"reward1",fn:function(ref){
             var item = ref.item;
return [_c('ProductRewardColumn',{attrs:{"rewards":item.rewards,"index":0,"sku":item.sku}})]}},{key:"reward2",fn:function(ref){
             var item = ref.item;
return [_c('ProductRewardColumn',{attrs:{"rewards":item.rewards,"index":1,"sku":item.sku}})]}},{key:"reward3",fn:function(ref){
             var item = ref.item;
return [_c('ProductRewardColumn',{attrs:{"rewards":item.rewards,"index":2,"sku":item.sku}})]}},{key:"more",fn:function(ref){
             var item = ref.item;
return [_c('td',[(item.rewards && item.rewards.length > 3)?_c('CButton',{attrs:{"size":"sm","color":"success"}},[_vm._v(_vm._s(item.rewards.length - 3)+" more...")]):_vm._e()],1)]}},{key:"new",fn:function(ref){
             var item = ref.item;
return [_c('td',[(item.rewards && item.rewards.length >= 3)?_c('CButton',{attrs:{"color":"primary","size":"sm","to":("/products/view/" + (item.sku) + "?rewards=new")}},[_c('span',{staticClass:"strong"},[_vm._v("+")])]):_vm._e()],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }